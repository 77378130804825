import { useState } from "react";
import { Button, Layout, Result, theme } from "antd";
import DxfUploader from "./components/DxfUploader";
import DataDisplay from "./components/data_display/DataDisplay";

const { Content, Footer } = Layout;

const App = () => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const {
    token: { colorBgContainer, borderRadiusLG },
  } = theme.useToken();

  return (
    <Layout style={{ height: "100vh" }}>
      <Content style={{ padding: "48px 48px 0px 48px" }}>
        <div
          style={{
            padding: 24,
            height: "100%",
            background: colorBgContainer,
            borderRadius: borderRadiusLG,
            overflowY: "auto",
          }}
        >
          {error ? (
            <div
              style={{
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <Result
                status="error"
                title={error?.response?.data ?? "Something went wrong."}
                extra={
                  <Button type="primary" onClick={() => setError(null)}>
                    Go back
                  </Button>
                }
              />
            </div>
          ) : data ? (
            <DataDisplay data={data} />
          ) : (
            <DxfUploader
              loading={loading}
              setLoading={setLoading}
              setError={setError}
              setData={setData}
            />
          )}
        </div>
      </Content>
      <Footer style={{ textAlign: "center" }}>Created by Signwork</Footer>
    </Layout>
  );
};

export default App;
